export default {
  header: "Menu",
  children: [
    {
      id: "dashboard",
      title: "Home",
      icon: "Curved-Home",
      navLink: "/",
    },
    {
      id: "usergames",
      title: "My Games",
      icon: "Curved-Game",
      children: [
        {
          id: "WIP",
          title: "WIP",
          navLink: "#",
        },
      ],
    },
    {
      id: "allgames",
      title: "Other Games",
      icon: "Curved-Game",
      children: [
        {
          id: "game-axieinfinity",
          title: "Axie Infinity",
          navLink: "/games/axieinfinity",
        },
        {
          id: "game-sunflowerland",
          title: "Sunflower Land",
          navLink: "/games/sunflowerland",
        },
        {
          id: "game-milliononmars",
          title: "Million on Mars",
          navLink: "/games/milliononmars",
        },
        {
          id: "game-genopets",
          title: "Genopets",
          navLink: "/games/genopets",
        },
        {
          id: "game-honeyland",
          title: "Honeyland",
          navLink: "/games/honeyland",
        },
        {
          id: "game-legendsofvenari",
          title: "Legends of Venari",
          navLink: "/games/legendsofvenari",
        },
        {
          id: "game-illuvium",
          title: "Illuvium",
          navLink: "/games/illuvium",
        },
        {
          id: "game-godsunchained",
          title: "Gods Unchained",
          navLink: "/games/godsunchained",
        },
        {
          id: "game-bigtime",
          title: "Big Time",
          navLink: "/games/bigtime",
        },
      ],
    },
    // {
    //   id: "support",
    //   title: "Support",
    //   icon: "Bold-ShieldDone",
    //   children: [
    //     {
    //       id: "support-faq",
    //       title: "FAQ",
    //       navLink: "/support/faq",
    //     },
    //     {
    //       id: "support-knowledgebase",
    //       title: "Knowledge Base",
    //       navLink: "/support/knowledgebase",
    //     },
    //     {
    //       id: "support-tickets",
    //       title: "Support Tickets",
    //       navLink: "/support/tickets",
    //     },
    //   ],
    // },
  ],
};
